import request from '@/utils/request';
export var defaultVideoArchive = {
  title: '',
  video_url: '',
  image_url: '',
  read_count: 0,
  category: '',
  description: '',
  tags: [],
  created_by: ''
};
export var getVideoArchives = function getVideoArchives(params) {
  return request({
    url: '/connect/api/v1/video/archives',
    method: 'get',
    params: params
  });
};
export var getVideoArchive = function getVideoArchive(id, params) {
  return request({
    url: "/connect/api/v1/video/archives/".concat(id),
    method: 'get',
    params: params
  });
};
export var createVideoArchive = function createVideoArchive(data) {
  return request({
    url: '/connect/api/v1/video/archives',
    method: 'post',
    data: data
  });
};
export var updateVideoArchive = function updateVideoArchive(data) {
  return request({
    url: "/connect/api/v1/video/archives/".concat(data.id),
    method: 'put',
    data: data
  });
};
export var deleteVideoArchive = function deleteVideoArchive(id) {
  return request({
    url: "/connect/api/v1/video/archives/".concat(id),
    method: 'delete'
  });
};