var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        title: _vm.title,
        "close-on-click-modal": false
      },
      on: {
        open: function($event) {
          return _vm.handleOpen()
        },
        close: function($event) {
          return _vm.handleClose()
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          staticStyle: { width: "80%", "margin-left": "50px" },
          attrs: {
            rules: _vm.formRules,
            model: _vm.formData,
            "label-position": "left",
            "label-width": "100px"
          }
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("videoArchive.category"),
                prop: "category"
              }
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "200px" },
                  attrs: {
                    placeholder: _vm.$t("videoArchive.category"),
                    clearable: ""
                  },
                  model: {
                    value: _vm.formData.category,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "category", $$v)
                    },
                    expression: "formData.category"
                  }
                },
                _vm._l(_vm.archiveTypes, function(item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { label: item.label, value: item.key }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("videoArchive.title"), prop: "title" } },
            [
              _c("el-input", {
                attrs: { placeholder: "Please input" },
                model: {
                  value: _vm.formData.title,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "title", $$v)
                  },
                  expression: "formData.title"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("videoArchive.description") } },
            [
              _c("el-input", {
                attrs: {
                  autosize: { minRows: 2, maxRows: 4 },
                  type: "textarea",
                  placeholder: "Please input"
                },
                model: {
                  value: _vm.formData.description,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "description", $$v)
                  },
                  expression: "formData.description"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("videoArchive.videoUrl"),
                prop: "video_url"
              }
            },
            [
              _c("el-input", {
                attrs: { placeholder: "Please input" },
                model: {
                  value: _vm.formData.video_url,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "video_url", $$v)
                  },
                  expression: "formData.video_url"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("videoArchive.imageUrl"),
                prop: "image_url"
              }
            },
            [
              _c("el-input", {
                attrs: { placeholder: "Please input" },
                model: {
                  value: _vm.formData.image_url,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "image_url", $$v)
                  },
                  expression: "formData.image_url"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("videoArchive.tags"), prop: "tags" } },
            [
              _c("el-input-tag", {
                model: {
                  value: _vm.formData.tags,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "tags", $$v)
                  },
                  expression: "formData.tags"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.handleClose()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("videoArchive.cancel")) + " ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  _vm.action === "create" ? _vm.createData() : _vm.updateData()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("videoArchive.confirm")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }